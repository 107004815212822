input[type="email"],
input[type="text"],
input[type="number"],
textarea,
select {
  width: 80%;
}

label {
  margin-top: 1rem;
}

label.checkbox {
  font-weight: normal;
}

button[type="submit"] {
  margin-top: 1rem;
}
